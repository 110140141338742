<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ name }}</h1>
                <p class="text-caption text-center">Account overview</p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center" v-if="account">{{ account.name }}</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- TODO: restore for enterprise accounts only ; individual accounts are free -->
                    <!-- <v-alert type="warning" v-if="isAccountBillingSummaryReady && (!accountBillingSummary || accountBillingSummary.error)">
                        Billing data not available at this time.
                        <a href="#" class="text-alert" @click="navigateToBilling">Manage billing</a>
                    </v-alert> -->
                    <v-card>
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-app-bar-title>Account overview</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <template v-if="isDocHostingEnabled">
                                <!-- TODO: leaving domains here in case customer will set aup a custom domain for hosting/sharing.... UI/serivce will still need to be customized -->
                            <!-- <p class="text-overline mb-0 mt-0">Domains</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">See all domains</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_count }})</span>
                            </p>
                            <p class="mb-0 pb-0" v-if="accountDataSummary && accountDataSummary.domain_verification_count">
                                Pending verifications<span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_verification_count}})</span>
                            </p> -->
                                <p class="text-overline mb-0 mt-0">Files</p>
                                <p class="mb-0 pb-0">
                                    <router-link :to="{ name: 'account-search-file', params: { accountId: this.$route.params.accountId } }">See all files</router-link>
                                    <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.file_count }})</span>
                                </p>
                                <!-- TODO: instead of pending verifications, it might be "pending leak search results" ? "pending shares" (that recipient didn't pick up yet) ?etc. -->
                                <!-- <p class="mb-0 pb-0" v-if="accountDataSummary && accountDataSummary.domain_verification_count">
                                    Pending verifications<span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_verification_count}})</span>
                                </p> -->
                            </template>
                            <!-- TODO: restore resources only for enterprise accounts, and change it from docs/dns to cryptium id things -->
                            <!-- <template v-if="!isDocHostingEnabled">
                                <p class="text-overline mb-0 mt-0">Domains</p>
                                <p>You don't have a Cryptium Docs plan.</p>
                                <p><a href="#" @click="subscribeToDocsHosting"><strong>Subscribe now to use Cryptium Docs</strong></a></p>
                            </template> -->
                            <!-- <p class="mb-0 pb-0" v-if="accountDataSummary">
                                DNS records<span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.dns_record_count}})</span>
                            </p> -->

                            <!-- TODO: instead of dyanmic dns hosting, because it's from libertydns, maybe check if there's an upgrade available like from essential -> professional -> business -> enterprise,  or some other up-sell available, that we can mention here -->
                            <!-- <template v-if="isDynamicDnsHostingEnabled">
                            <p class="text-overline mb-0 mt-8">Dynamic DNS</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-dynamic-dns-record', params: { accountId: this.$route.params.accountId } }">See all Dynamic DNS records</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.dynamic_dns_count }})</span>
                            </p>
                            </template>
                            <template v-if="!isDynamicDnsHostingEnabled">
                                < ! - - TODO: add a notice and button to subscribe to a plan!! - - >
                            </template> -->

                            <p class="text-overline mb-0 mt-8">Users</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">See all users</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.user_count }})</span>
                            </p>

                            <!-- TODO: remove, this is from libertydns -->
                            <!-- <template v-if="accountDataSummary && (accountDataSummary.domain_dispute_claimant_count > 0 || accountDataSummary.domain_dispute_defendant_count > 0)">
                            <p class="text-overline mb-0 mt-8">Disputes</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain-dispute', params: { accountId: this.$route.params.accountId }, query: { role: 'claimant' } }">See all disputes for a domain name</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_dispute_claimant_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain-dispute', params: { accountId: this.$route.params.accountId }, query: { role: 'defendant' } }">See all disputes against you</router-link>
                                <span v-if="accountDataSummary" class="ml-2">({{ accountDataSummary.domain_dispute_defendant_count }})</span>
                            </p>
                            </template> -->

                            <!-- TODO: we might link to other identity related tools like identity theft monitoring, web search for negative info about yourself, etc. -->
                            <!--
                            <p class="text-overline mb-0 mt-8">Tools</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-leak-detection', params: { accountId: this.$route.params.accountId } }">Set up leak detection for your documents</router-link>
                            </p>
                            -->

                            <!-- TODO: remove these, they are from libertydns: -->
                            <!-- <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-nameservers', params: { accountId: this.$route.params.accountId } }">Check nameservers for a domain</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-whois', params: { accountId: this.$route.params.accountId } }">Check whois for a domain</router-link>
                            </p> -->
                            <!-- <AccountViewList :list="list"/> -->
                            <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->

                            <!-- TODO: restore billing only for enterprise accounts -->
                            <!--
                            <p class="text-overline mb-0 mt-8">Billing</p>
                            <template v-if="isAccountBillingSummaryReady && accountBillingSummary && !accountBillingSummary.error">
                            <p class="mb-0 pb-0" v-if="isDocHostingEnabled">
                                Active subscription: {{ docsHostingTypeDisplayName(accountBillingSummary.dns_hosting_type) }}
                            </p>
                            <p class="mb-0 pb-0">
                                Account status: {{ accountBillingSummary.is_open ? 'open' : 'closed' }}
                            </p>
                            <p class="mb-0 pb-0">
                                Balance: ${{ accountBillingSummary.balance_amount }}
                                Credit: ${{ accountBillingSummary.credit_amount }}
                            </p>
                            <p class="mb-0 pb-0">
                                <a href="#" @click="navigateToBilling">
                                {{ accountBillingSummary.is_payment_due ? 'Payment is due' : 'No payment is due' }}
                                {{ accountBillingSummary.is_payment_late ? '(late)' : '' }}
                                </a>
                            </p>
                            </template>
                            -->

                            <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                            </p>

                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text"/></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>
a.text-alert, a.text-alert:visited {
    color: #ffffff;
}
</style>

<script>
import { mapState } from 'vuex';
// import UserList from '@/components/account-dashboard/UserList.vue';

export default {
    components: {
        // UserList,
    },
    data: () => ({
        accountDataSummary: null,
        accountBillingSummary: null,
        accountDisputeSummary: null,
        error: null,
        isAccountBillingSummaryReady: false, // prevents flicker on related UI items
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        name() {
            return this.account?.name ?? 'Unknown';
        },
        // isPermitServiceAdmin() {
        //     return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        // },
        isDocHostingEnabled() {
            return ['essential', 'business', 'enterprise'].includes(this.accountBillingSummary?.dns_hosting_type);
        },
        isDynamicDnsHostingEnabled() {
            return false;
        },
    },
    watch: {
        focus() {
            // reload data when user returns to this tab
            this.loadAccountBillingSummary();
            this.loadAccountDataSummary();
            this.loadAccountDisputeSummary();
        },
    },
    methods: {
        // TODO: get the display name from unicorn springs service name; right now we're not saving that into the account status when we do the billing check in account api
        docsHostingTypeDisplayName(ref) {
            let displayName = null;
            switch (ref) {
            case 'essential':
                displayName = 'Essential Cryptium Docs';
                break;
            case 'professional':
                displayName = 'Professional Cryptium Docs';
                break;
            case 'business':
                displayName = 'Business Cryptium Docs';
                break;
            case 'enterprise':
                displayName = 'Enterprise Cryptium Docs';
                break;
            default:
                displayName = 'Cryptium Docs';
                break;
            }
            return displayName;
        },
        async loadAccountDataSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountDataSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.check({ item: 'data' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountDataSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"dns_record_count":4}
                } else {
                    this.accountDataSummary = null;
                }
            } catch (err) {
                console.error('loadAccountDataSummary: failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountDataSummary: false });
            }
        },
        async loadAccountBillingSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountBillingSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.check({ item: 'billing' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountBillingSummary = response; // {"is_open":true,"is_payment_due":true,"is_payment_late":false,"balance_amount":"100.00","credit_amount":"20.00","status_on":1234567890}
                } else {
                    this.accountBillingSummary = null;
                }
            } catch (err) {
                console.error('loadAccountBillingSummary: failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountBillingSummary: false });
                this.isAccountBillingSummaryReady = true;
            }
        },
        async loadAccountDisputeSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountDisputeSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.check({ item: 'dispute' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountDisputeSummary = response; // {"domain_dispute_defendant":0,"domain_dispute_claimant":0}
                } else {
                    this.accountDisputeSummary = null;
                }
            } catch (err) {
                console.error('loadAccountDisputeSummary: failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountDisputeSummary: false });
            }
        },
        async navigateToBilling() {
            try {
                this.error = false;
                this.$store.commit('loading', { navigateToBilling: true });
                const response = await this.$client.account(this.$route.params.accountId).unicornsprings.connect({ intent: 'billing' });
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('navigateToBilling: failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { navigateToBilling: false });
            }
        },
        async subscribeToDocsHosting() {
            try {
                this.error = false;
                this.$store.commit('loading', { navigateToBilling: true });
                const response = await this.$client.account(this.$route.params.accountId).unicornsprings.connect({ intent: 'cart', product_ref: 'essential' }); // TODO: replace product_ref 'essential' with a catalog tag like 'dnshosting' that will bring up all three plans 'essential', 'business', and 'enterprise' (or whatever is published)
                // console.log(`response ${JSON.stringify(response)}`); // {"redirect":"https://...","redirect_max_seconds":300}
                if (response?.redirect) {
                    window.location.href = response.redirect;
                }
            } catch (err) {
                console.error('navigateToBilling: failed to get link to Unicorn Springs', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to connect with Unicorn Springs' });
            } finally {
                this.$store.commit('loading', { navigateToBilling: false });
            }
        },
    },
    mounted() {
        this.loadAccountBillingSummary();
        this.loadAccountDataSummary();
        this.loadAccountDisputeSummary();
    },
};
</script>
